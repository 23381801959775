var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-actions',[(_vm.filtro)?_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("FILTRO")]),_c('v-row',{staticClass:"pt-7 pl-7"},[_c('v-col',{staticStyle:{"min-width":"200px"},attrs:{"cols":"3","md":"2"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.desde,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.desde=$event},"update:return-value":function($event){_vm.desde=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.desde),callback:function ($$v) {_vm.desde=$$v},expression:"desde"}},'v-text-field',attrs,false),on))]}}],null,false,4237676199),model:{value:(_vm.desdeModal),callback:function ($$v) {_vm.desdeModal=$$v},expression:"desdeModal"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.desde),callback:function ($$v) {_vm.desde=$$v},expression:"desde"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.desdeModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.desde)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticStyle:{"min-width":"200px"},attrs:{"cols":"3","md":"2"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.hasta,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.hasta=$event},"update:return-value":function($event){_vm.hasta=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha fin","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.hasta),callback:function ($$v) {_vm.hasta=$$v},expression:"hasta"}},'v-text-field',attrs,false),on))]}}],null,false,1822298865),model:{value:(_vm.hastaModal),callback:function ($$v) {_vm.hastaModal=$$v},expression:"hastaModal"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.hasta),callback:function ($$v) {_vm.hasta=$$v},expression:"hasta"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.hastaModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.hasta)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticStyle:{"min-width":"200px"},attrs:{"cols":"3","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"green"},on:{"click":function($event){return _vm.Buscar()}}},'v-icon',attrs,false),on),[_vm._v(" fa-search ")])]}}],null,false,1025597087)},[_c('span',[_vm._v("Buscar")])])],1)],1)],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",on:{"click":function($event){return _vm.SampleMethod(item)}}},'v-icon',attrs,false),on),[_vm._v(" fa-eye ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalles")])]),(_vm.conPDF)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.GenerarPDF(item)}}},'v-icon',attrs,false),on),[_vm._v(" fa-file-pdf ")])]}}],null,true)},[_c('span',[_vm._v("PDFs")])]):_vm._e()]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c(_vm.componentName,{tag:"component",on:{"onCancel":function($event){_vm.modal=false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }