

























import {Vue, Component, Prop} from 'vue-property-decorator'
@Component({
    components:{
        
    }
})

export default class extends Vue
{
    
    @Prop({type:String ,required:true, default:''}) titulo!:string;
    @Prop({type:Boolean ,required:true, default:true}) esTabla!:string;
    @Prop({type:Object ,required:false, default:null}) data!:Object;
    @Prop({type:Array ,required:true,  default:new Array(
        [
        {
          text: 'Campo1',
          align: 'center',
          sortable: false,
          value: 'valor1'
        },
        {
          text: 'Campo2',
          align: 'center',
          sortable: false,
          value: 'valor2'
        },
        { text: 'Acciones', align: 'center', value: 'acciones', sortable: false }, 
    ]
    )}) headers!:any[];
    @Prop({type:Array ,required:false, default:new Array()}) items!:any[];
    
}

