











































































































































































import {Vue, Component, Prop} from 'vue-property-decorator'
import Confirmacion from '@/components/Confirmacion.vue'
import {State, Action, Mutation} from 'vuex-class'
import { mensajes } from '@/plugins/notify';
@Component({
    components:{
        Confirmacion,
    }
})

export default class extends Vue
{
    @Prop({type:Boolean ,required:true, default:false}) filtro!:Boolean;
    @Prop({type:Array ,required:true,  default:new Array([
        {
          text: 'Campo1',
          align: 'center',
          sortable: false,
          value: 'valor1'
        },
        {
          text: 'Campo2',
          align: 'center',
          sortable: false,
          value: 'valor2'
        },
        { text: 'Acciones', align: 'center', value: 'acciones', sortable: false }, 
    ])}) headers!:any[];
    @Prop({type:Array ,required:true, default:new Array()}) items!:any[];
    @Prop({type:Boolean ,required:false, default:true}) conPDF!:Boolean;
    @Action('enviar', {namespace:'parteModule'}) enviar!:Function;
    @Mutation('addParte', {namespace:'parteModule'} ) addParte!:Function;
    @State('partes', {namespace:'parteModule'} ) partes!: any[];
    componentName='Confirmacion'
    modal=false;
    modal2=false;
    desde='';
    hasta='';
    desdeModal=false;
    hastaModal=false;
    sample=new Array()

    SampleMethod(item: any){
        this.$emit('verDetalles',item)
    }
    Buscar(){
        if(this.desde.length>1 && this.hasta.length>1){
            this.$emit('onSearch',{
                desde:this.desde,
                hasta: this.hasta
            })
        }else{
            mensajes.error('Fechas invalidas');
        }
    }
    GenerarPDF(item: any){
        this.$emit('pdfGen', item)
    }
    
}

